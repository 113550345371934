import React from 'react';
import { useParams, Link } from "react-router-dom";
import cx from 'classnames';
import SliderContext from './context'
import ShowDetailsButton from './ShowDetailsButton'
import Mark from './Mark'
import './Item.scss'
import formatName from '../../utils/formatName'

const Item = ({ movie }) => (
  <SliderContext.Consumer>
    {({ onSelectSlide, currentSlide, elementRef }) => {
      const isActive = currentSlide && currentSlide._id === movie._id;

      return (
        <div
          ref={elementRef}
          className={cx('item', {
            'item--open': isActive,
          })}
        >
          <Link style={{ textDecoration: "none", color:"black" }} to={`/video/${movie._id}`}>
          <img src={movie.imgsrc} alt="" />
          <div style={{position:"absolute", bottom: -15, left:0, right:0}}>
            <p style={{whiteSpace:"nowrap"}}>{formatName(movie.name)}</p>
          </div>
          {/* <ShowDetailsButton onClick={() => onSelectSlide(movie)} /> */}
          {isActive && <Mark />}
          </Link>
        </div>
      );
    }}
  </SliderContext.Consumer>
);

export default Item;
