import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Video2 from './Video2';
import WelcomeModal from './WelcomeModal';

const MINUTE_MS = 4000;
let video_arr_ = [];
const api = require('../api.json');

const Home = () => {
	const goFullScreen = () => {
		if (document.documentElement.requestFullscreen) {
			document.documentElement.requestFullscreen();
		} else if (document.documentElement.mozRequestFullScreen) {
			/* Firefox */
			document.documentElement.mozRequestFullScreen();
		} else if (document.documentElement.webkitRequestFullscreen) {
			/* Chrome, Safari and Opera */
			document.documentElement.webkitRequestFullscreen();
		} else if (document.documentElement.msRequestFullscreen) {
			/* IE/Edge */
			document.documentElement.msRequestFullscreen();
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			getVideo(video_arr_);
			getLiveVideo();
		}, MINUTE_MS);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		firstTime();
		getLiveVideo();
	}, []);

	const [ isLiveVideo, setIsLiveVideo ] = useState(false);
	const [ liveVideoUrl, setLiveVideoUrl ] = useState('');

	const [ currentVid, setCurrentVid ] = useState('');
	const [ visible, setVisible ] = useState(true);
	const [ primeraVez, setPrimeraVez ] = useState(false);

	const getVideo = async (videos) => {
		const date = await getTime();
		const midnight = await getTime();
		midnight.setHours(0, 0, 0, 0);

		const diff = (date.getTime() - midnight.getTime()) / 1000;
		let sum = 0;
		videos.map((video) => {
			sum = sum + parseInt(video.time);
		});

		if (sum > diff) {
			let aEsteSegundoHayQueIr = diff % sum;
			let alFinalizarEsteVideoSegundos = 0;
			let alIniciarVideoSegundos = 0;

			for (let i = 0; i < videos.length; i++) {
				alFinalizarEsteVideoSegundos += parseInt(videos[i].time);
				alIniciarVideoSegundos = alFinalizarEsteVideoSegundos - parseInt(videos[i].time);

				if (
					alFinalizarEsteVideoSegundos > aEsteSegundoHayQueIr &&
					alIniciarVideoSegundos < aEsteSegundoHayQueIr
				) {
					aEsteSegundoHayQueIr = aEsteSegundoHayQueIr - alIniciarVideoSegundos;
					var minutes = Math.floor(aEsteSegundoHayQueIr / 60);
					var seconds = aEsteSegundoHayQueIr - minutes * 60;
					let end;
					if (primeraVez == false) {
						end =
							'?autoplay=1&muted=0&controls=0&autopause=false' +
							'#t=' +
							parseInt(minutes).toString() +
							'm' +
							parseInt(seconds).toString() +
							's';
						setPrimeraVez(true);
					} else {
						end =
							'?autoplay=1&muted=0&controls=0&autopause=false' +
							'#t=' +
							parseInt(minutes).toString() +
							'm' +
							parseInt(seconds).toString() +
							's';
					}
					setCurrentVid(videos[i].videosrc + end);
					return;
				}
			}
		} else {
			let aEsteSegundoHayQueIr = diff % sum;
			let alFinalizarEsteVideoSegundos = 0;
			let alIniciarVideoSegundos = 0;

			for (let i = 0; i < videos.length; i++) {
				alFinalizarEsteVideoSegundos += parseInt(videos[i].time);
				alIniciarVideoSegundos = alFinalizarEsteVideoSegundos - parseInt(videos[i].time);

				if (
					alFinalizarEsteVideoSegundos > aEsteSegundoHayQueIr &&
					alIniciarVideoSegundos < aEsteSegundoHayQueIr
				) {
					aEsteSegundoHayQueIr = aEsteSegundoHayQueIr - alIniciarVideoSegundos;
					var minutes = Math.floor(aEsteSegundoHayQueIr / 60);
					var seconds = aEsteSegundoHayQueIr - minutes * 60;
					let end;
					if (primeraVez == false) {
						end =
							'?autoplay=1&muted=0&controls=0&autopause=false' +
							'#t=' +
							parseInt(minutes).toString() +
							'm' +
							parseInt(seconds).toString() +
							's';
						setPrimeraVez(true);
					} else {
						end =
							'?autoplay=1&muted=0&controls=0&autopause=false' +
							'#t=' +
							parseInt(minutes).toString() +
							'm' +
							parseInt(seconds).toString() +
							's';
					}
					setCurrentVid(videos[i].videosrc + end);
					return;
				}
			}
		}
	};

	const getTime = async () => {
		const url = api.endpoint + '/time';
		try {
			const time = await axios.get(url);
			return new Date(time.data.date);
		} catch (err) {
			console.error('error', err);
		}
	};

	const firstTime = async () => {
		const url = api.endpoint + '/getCurrentVideo';
		try {
			const response = await axios.get(url);
			getVideo(response.data);
			video_arr_ = response.data;
		} catch (err) {
			console.error('error', err);
		}
	};

	const getLiveVideo = async () => {
		const url = api.endpoint + '/live-video';
		try {
			const response = await axios.get(url);
			if (response.data[0].isLive && response.data[0].isScheduled) {
				var date = await getTime();
				var minutes = date.getMinutes() - response.data[0].minuteToPlay;
				var seconds = date.getSeconds() - response.data[0].secondToPlay;
				const end =
					'?autoplay=1&muted=0&controls=0&autopause=false' +
					'#t=' +
					parseInt(minutes).toString() +
					'm' +
					parseInt(seconds).toString() +
					's';
				setLiveVideoUrl(response.data[0].liveVideoUrl + end);
				setIsLiveVideo(response.data[0].isLive);
			} else {
				setLiveVideoUrl(response.data[0].liveVideoUrl);
				setIsLiveVideo(response.data[0].isLive);
			}
		} catch (err) {
			console.error('error', err);
		}
	};

	return (
		<div style={{ backgroundColor: 'black' }}>
			<WelcomeModal
				visible={visible}
				onClose={() => {
					//goFullScreen();
					setVisible(false);
				}}
			/>
			{!visible && <Video2 src={isLiveVideo ? liveVideoUrl : currentVid} />}
		</div>
	);
};

export default Home;
