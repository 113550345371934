import React, { useEffect, useState } from 'react';
import { InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useWindowDimensions from '../hooks/useWindowDimensions';

export default function Searchbar({ onChange }) {
	const { height, width } = useWindowDimensions();
	const [ term, setTerm ] = useState('');
	const [ isActive, setIsActive ] = useState(true);
	const idleTimeout = 3000; // idle time in ms

	let timeoutId = null;

	function search() {
		window.location.href = '/#/search/' + term;
	}

	const resetTimeout = () => {
		setIsActive(true);
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => setIsActive(false), idleTimeout);
	};

	useEffect(() => {
		window.addEventListener('click', resetTimeout);
		window.addEventListener('keydown', resetTimeout);

		return () => {
			window.removeEventListener('click', resetTimeout);
			window.removeEventListener('keydown', resetTimeout);
		};
	}, []);

	useEffect(() => {
		timeoutId = setTimeout(() => setIsActive(false), idleTimeout);
		return () => clearTimeout(timeoutId);
	}, []);

	if (!isActive) {
		return null;
	}

	return (
		<div
			style={{
				position: 'absolute',
				top: 20,
				left: 0,
				right: 0,
				margin: 'auto',
				paddingLeft: '25vw',
				paddingRight: '25vw'
			}}
		>
			<div style={{ display: 'flex', borderRadius: 24, alignItems: 'center', justifyContent: 'center' }}>
				<InputBase
					style={{
						backgroundColor: 'white',
						paddingLeft: 24,
						borderRadius: 24,
						borderColor: 'black',
						borderWidth: 10,
						paddingRight: 30
					}}
					placeholder="Buscar video"
					onChange={(e) => {
						setTerm(e.target.value);
						onChange(e.target.value);
					}}
				/>
				<button
					style={{
						marginLeft: -36,
						zIndex: 10,
						borderRadius: 24,
						background: 'none',
						color: 'inherit',
						border: 'none',
						padding: 0,
						font: 'inherit',
						cursor: 'pointer',
						outline: 'inherit'
					}}
					onClick={() => {
						if (width < 960) {
							search();
						}
					}}
				>
					<div style={{ marginTop: 4, marginLeft: 6 }}>
						<SearchIcon fontSize={'small'} />
					</div>
				</button>
			</div>
		</div>
	);
}
