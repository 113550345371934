import React, { useState } from "react";
import { Box, Modal, Typography, Button } from "@material-ui/core";
import Video2 from "./Video2";

export default function WelcomeModal({ visible, onClose }) {
  const handleClose = () => {
    onClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    backgroundColor: "black",
    justifyContent: "center",
    textAlign: "-webkit-center"
  };

  return (
    <div>
      <Modal
        open={visible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={true}
        onClick={handleClose}
        >
        <Box sx={style} style={{display: 'flex', justifyContent: 'center'}}>
           <Typography
            id="modal-modal-title"
            style={{ position:'absolute', textAlign: "center", color: "#f0f0f0", top: 40, left:50, right:50 }}
          >
            Haga dos clicks en cualquier lugar para 
            comenzar a visualizar el contenido
          </Typography> 
          <img alt='logo' style={{display: "block", margin:"auto", width:"50%", maxWidth:400}} src="./ius.png"/>
          {/* <Button
            onClick={handleClose}
            style={{
              marginTop: 40,
              backgroundColor: "#fff",
              color: "#000",
              borderLeft: 50,
              display: "flex",
              justifyContent: "center",
              alignContent: "center"
            }}
          >
            Continuar
          </Button> */}
        </Box>
      </Modal>
    </div>
  );
}
