import React, { useState } from 'react';
import './App.css';
import Audiovisual from './components/Audiovisual';
import WelcomeModal from './components/WelcomeModal';

function App() {
  const [showAudiovisual, setShowAudiovisual] = useState(false);
  const [visible, setVisible] = useState(true);

  const goFullScreenAndLoadAudiovisual = () => {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }

    setShowAudiovisual(true);
    setVisible(false);
  };

  return (
    <div className="App">
      {visible && (
        <WelcomeModal
          visible={visible}
          onClose={goFullScreenAndLoadAudiovisual}
        />
      )}
      {showAudiovisual && <Audiovisual/>}
    </div>
  );
}

export default App;