import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import Searchbar from "./Searchbar";
import { Link } from "react-router-dom";
import { ReactComponent as Back } from "../arrow-left.svg";
import Button from "@material-ui/core/Button";

function Video2({ src }) {
  const size = useWindowSize();

  const [search, setSearch] = useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",

      color: theme.palette.text.secondary,
    },
  }));

  const useStyles2 = makeStyles({
    root: {
      flexGrow: 1,
    },
    root2: {
      margin: "auto",
      minWidth: 275,
      width: size.width - 100,
      padding: 10,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  const classes = useStyles();
  const classes2 = useStyles2();

  const [data, setData] = useState({});
  const [semestre, setSemestre] = useState({});
  const [unidad, setUnidad] = useState({});
  const [curso, setCurso] = useState({});
  const [loading, setLoading] = useState(true);
  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    let api = require("../api.json");
    const url = api.endpoint + "/video/";

    const fetchData = async () => {
      try {
        const response = await axios.get(url, {
          params: {
            videoid: id,
          },
        });
        setData(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
    esperarTiempo();
  }, []);

  const esperarTiempo = async () => {
    const timeW = 3000;
    setTimeout(() => {
      setMinimumTimeElapsed(false);
    }, timeW);
  };

  return (
    <div
      onMouseLeave={() => setSearch(false)}
      onMouseOver={() => setSearch(true)}
      className={classes.root}
    >
      {/* {search && <Searchbar />} */}
      <Link
        to={"/"}
        style={{
          textDecoration: "none",
          position: "absolute",
          top: 15,
          left: 10,
          backgroundColor: "white",
          borderRadius: 8,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button style={{textTransform: 'none'}}>
          <Back />
          <Typography style={{ color: "#000", paddingLeft: 10, fontSize: 18  }}>
            Regresar
          </Typography>
        </Button>
      </Link>
      <iframe
        style={{ margin: 0, padding: 0 }}
        src={data.videosrc}
        width={size.width}
        height={size.height}
        frameborder="0"
        allow="autoplay; fullscreen"
      ></iframe>
    </div>
  );
}

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
export default Video2;
