import React from 'react';
import Slider from './NetflixSlider'
import './App.scss'

/* const movies = [
  {
    id: 1,
    image: '/images/slide1.jpg',
    imageBg: '/images/slide1b.webp',
    title: '1983'
  },
  {
    id: 2,
    image: '/images/slide2.jpg',
    imageBg: '/images/slide2b.webp',
    title: 'Russian doll'
  },
  {
    id: 3,
    image: '/images/slide3.jpg',
    imageBg: '/images/slide3b.webp',
    title: 'The rain',
  },
  {
    id: 4,
    image: '/images/slide4.jpg',
    imageBg: '/images/slide4b.webp',
    title: 'Sex education'
  },
  {
    id: 5,
    image: '/images/slide5.jpg',
    imageBg: '/images/slide5b.webp',
    title: 'Elite'
  },

]; */

const App = ({videos}) => {
  return (
    <div className="app">
        <Slider>
          {videos.map(video => (
            <Slider.Item movie={video} key={video._id}>item1</Slider.Item>
          ))}
        </Slider>
      </div>
    );
}

export default App;
