import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Searchbar from "./Searchbar"
import useWindowDimensions from "../hooks/useWindowDimensions"
import Slider from "./Slider"
let api = require("../api.json");


function Video2({ src }) {
  const size = useWindowSize();
  const { height, width } = useWindowDimensions();


  const [search, setSearch] = useState(true);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      overflow: 'hidden', // Add this line to hide the scrollbars
      /* paddingTop: 30,
      paddingBottom: 30, */
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  }));

  const useStyles2 = makeStyles({
    root: {
      margin: "auto",
      minWidth: 275,
      backgroundColor: "#333333",
      width: size.width - 100,
      padding: 10,
    },
    root2: {
      margin: "auto",
      minWidth: 275,
      width: size.width - 100,
      padding: 10,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  const classes = useStyles();
  const classes2 = useStyles2();

  const [data, setData] = useState({});
  const [semestre, setSemestre] = useState({});
  const [unidad, setUnidad] = useState({});
  const [curso, setCurso] = useState({});
  const [loading, setLoading] = useState(true);
  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
  const [searchValue, setSearchValue] = useState('')
  const [videosArray, setVideosArray] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    const url = api.endpoint + "/watch/" + id;

    const fetchData = async () => {
      const result = await axios(url);

      setData(result.data[0]);
      setSemestre(result.data[0].semestreid);
      setUnidad(result.data[0].unidadid);
      setCurso(result.data[0].cursoid);
      setLoading(false);
    };

    fetchData();
    esperarTiempo();
  }, []);

  useEffect(() => {
    async function getVideos() {
      await fetch(api.endpoint + `/search?page=1&limit=100&search=${searchValue}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (!responseJson.error) {
            setVideosArray(responseJson.slice(0,5));
          } else {
            console.error("Hubo un error la consulta.");
            console.error(responseJson.error);
          }
        })
        .catch((error) => {
          console.error("Hubo un error la consulta.");
          console.error(error);
        });
    }
    if(searchValue != '') getVideos();
  }, [searchValue])

  const esperarTiempo = async () => {
    const timeW = 1000;
    setTimeout(() => {
      setMinimumTimeElapsed(false);
    }, timeW);
  };

  return (
    <div onMouseLeave={()=> setSearch(false)} onMouseOver={()=> setSearch(true)} className={classes.root}>
      <div>
        {/*  search && */ <Searchbar onChange={(value)=> setSearchValue(value)}/>}
        
      </div>
          <iframe
            style={{ margin: 0, padding: 0}}
            src={src}
            width={size.width}
            height={size.height}
            frameborder="0"
            allow="autoplay; fullscreen"
          ></iframe>
          {
            searchValue != '' && 
            <div style={{position:"absolute", bottom: 0}}>
            {(width > 960) && <Slider videos={videosArray} />}
          </div>
          }
          
    </div>
  );
}

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
export default Video2;
