import React, { useState, useEffect } from "react";
import GetMaterial from "./GetMaterial";
import Home from "./Home";
import SearchList from "./SearchList";
import Video from "./Video";
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import WelcomeModal from "./WelcomeModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    paddingTop: 40,
  },
  title: {
    textAlign: "left",
    flexGrow: 1,
    marginLeft: 10,
    marginRight: 20,
  },
  footer: {
    padding: 20,
  },
  search: { display: "flex" },
  textField: {
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    borderBottomColor: "#fff",
  },
  input: {
    color: "#333",
    fontSize: 14,
    backgroundColor: "#ebebeb",
    height: 36,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    padding: 4,
  },
}));
function Audiovisual() {
  const classes = useStyles();
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState();
  const [open, setOpen] = useState(true);

  const Copyright = () => {
    return new Date().getFullYear();
  };

  function goBack() {
    window.history.back();
  }

  function search() {
    window.location.href = "/#/search/" + searchTerm;
    setSearchTerm("");
  }

  return (
    <div style={{backgroundColor:"black"}}>
          {open && <WelcomeModal/>}
            <Router>
              <Switch>
                <Route path="/get">
                  <GetMaterial />
                </Route>
                <Route path="/search/:search">
                  <SearchList />
                </Route>
                <Route path="/video/:id">
                  <Video />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </Router>
    </div>
  );
}

export default Audiovisual;
