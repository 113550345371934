import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";


function CRUD() {

const [videos, setVideos] = useState([]);
const [categoria, setCategoria] = useState([]);
const [expositor, setExpositor] = useState([]);
const [vCate, setVCate] = useState([]);
const [vExpo, setVExpo] = useState([]);

  const fetchVideos = async () => {
    let api = require("../api.json");
      fetch(api.endpoint + "/videos?page=1&limit=100", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (!responseJson.error) {
            setVideos(responseJson)
          } else {
            console.error(responseJson.error);
          }
        })
        .catch((error) => {
          console.error(error);
        });
  };

  useEffect(() => {
    fetchVideos();
  }, []);




    return(
        <div >
            <h1>CRUD</h1>
            <br></br>
            <br></br>
            {/*<h3>LISTA DE CATEGORIA</h3>
            <table style={{marginLeft:"auto",marginRight:"auto"}}>
            <tr>
                <th>ID</th>
                <th>Nombre</th>
            </tr>
            {
                categoria.map(elemento =>(
                    <tr>
                        <td> {elemento._id} </td>
                        <td> {elemento.nombre} </td>
                    </tr>
                ))
            }
            </table>
            <br></br>
            <br></br>
            <br></br>
            <h3>LISTA DE EXPOSITOR</h3>
            <table style={{marginLeft:"auto",marginRight:"auto"}}>
            <tr>
                <th>ID</th>
                <th>Prefijo</th>
                <th>Nombre</th>
                <th>Apellidos</th>
            </tr>
            {
                expositor.map(elemento =>(
                    <tr>
                    <td> {elemento._id} </td>
                        <td> {elemento.prefijonombre} </td>
                        <td> {elemento.nombre} </td>
                        <td> {elemento.apellidos} </td>
                    </tr>
                ))
            }
            </table>
            <br></br>
            <br></br>
          <br></br>*/}

            <h3>LISTA DE VIDEOS</h3>

              
             
             <table style={{marginLeft:"auto",marginRight:"auto"}}>
            <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Videosrc</th>
                <th>Fecha publicado</th>
                <th>Img src</th>
                <th>Descripcion</th>
                <th>Autor</th>
                <th>Categoría</th>

            </tr>
            {
                videos.map(elemento =>(
                    <tr>
                    <td> {elemento._id} </td>
                    <td> {elemento.name} </td>
                    <td> {elemento.videosrc} </td>
                    <td> {elemento.fechapublicado} </td>
                    <td> {elemento.imgsrc} </td>
                    <td> {elemento.descripcion} </td>
                    <td> {elemento.autor} </td>
                    <td> {elemento.categoria} </td>


                        {/*<td> 
                        <iframe
                        style={{ margin: 0, padding: 0, paddingTop: 20 }}
                        src={elemento.videosrc}
                        width={40}
                        frameborder="0"
                        allow="autoplay; fullscreen"
                        allowfullscreen
                        ></iframe>
                        
                        </td>*/}

                    </tr>
                ))
            }
            </table>
            <br></br>
            <br></br>
            <br></br>

            {/*<h3>LISTA DE VIDEOS - CATEGORIA</h3>
             
             <table style={{marginLeft:"auto",marginRight:"auto"}}>
            <tr>
                <th>ID</th>
                <th>Video ID</th>
                <th>Categoria ID</th>
            </tr>
            {
                vCate.map(elemento =>(
                    <tr>
                    <td> {elemento._id} </td>
                        <td> {elemento.videoid} </td>
                        <td> {elemento.categoriaid} </td>
                    </tr>
                ))
            }
            </table>
            <br></br>
            <br></br>
            <br></br>

            <h3>LISTA DE VIDEOS - EXPOSITOR</h3>
             
             <table style={{marginLeft:"auto",marginRight:"auto"}}>
            <tr>
                <th>ID</th>
                <th>Video ID</th>
                <th>Expositor ID</th>
            </tr>
            {
                vExpo.map(elemento =>(
                    <tr>
                    <td> {elemento._id} </td>
                        <td> {elemento.videoid} </td>
                        <td> {elemento.expositorid} </td>

                    </tr>
                ))
            }
            </table>*/}

        </div>

    )
}

export default CRUD