import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Searchbar from './Searchbar'
import Button from "@material-ui/core/Button";
import { ReactComponent as Back } from "../arrow-left.svg";
import formatName from '../utils/formatName'

const Result = () => {
  const size = useWindowSize();

  const useStylesBox = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    card2: {
      height: 35,
    },
    cardMedia: {
      paddingTop: "65.7%", // 16:9
    },
    cardMedia2: {
      paddingTop: "56.25%", // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
    typo: {
      paddingTop: "20%",
      paddingBottom: "20%",
    },
  }));

  const useStyles2 = makeStyles({
    root: {
      margin: "auto",
      minWidth: 275,
      width: size.width - 500,
      padding: 10,
      alignItems: "center",
      justifyContent: "center",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  const useStylesModal = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      border: "0px solid #000",
    },
  }));

  const [loading, setLoading] = useState(true);
  const [videosArray, setvideosArray] = useState([]);

  const { search } = useParams();

  const classes2 = useStyles2();
  let api = require("../api.json");
  const url = api.endpoint + "/videos/";

  useEffect(async () => {
    async function getVideos() {
      let api = require("../api.json");
      fetch(api.endpoint + `/search?page=1&limit=100&search=${search}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (!responseJson.error) {
            setvideosArray(responseJson);
          } else {
            console.error("Hubo un error la consulta.");
            console.error(responseJson.error);
          }
        })
        .catch((error) => {
          console.error("Hubo un error la consulta.");
          console.error(error);
        });
    }
    await getVideos();
  }, [search]);

  function formatDate(timestamp) {
    var x = new Date(timestamp);
    var dd = x.getDate();
    var mm = x.getMonth() + 1;
    var yy = x.getFullYear();
    return dd + "/" + mm + "/" + yy;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <div className={classes2.root} style={{backgroundColor:"black", paddingTop:64}}>
      <Searchbar/>
      <Link
        to={"/"}
        style={{
          textDecoration: "none",
          position: "absolute",
          top: 15,
          left: 10,
          backgroundColor: "white",
          borderRadius: 8,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button size={'small'}>
          <Back />
          {size.width > 378 && <Typography style={{ color: "#000", paddingLeft: 10, fontSize: 12 }}>
            INICIO
          </Typography>}
        </Button>
      </Link>
    
     <Grid container spacing={4}>
        {videosArray.map((item) => (
          <Grid item key={item._id} xs={12} sm={12} md={4}>
            <Link style={{ textDecoration: "none" }} to={`/video/${item._id}`}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="240"
                  image={item.imgsrc}
                  alt={item.name}
                />
                <CardContent>
                  <Typography style={{whiteSpace:"nowrap"}} gutterBottom variant="subtitle1" component="div">
                    {formatName(item.name)}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
export default Result;
